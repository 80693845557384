import { FC, useContext, useEffect, useState } from 'react'
// import imgMobile from '../../../assets/images/eventCaption/Electric-offer-head-mobile.png'
// import imgDesk from '../../../assets/images/eventCaption/Electric-offer-head.avif'
import styles from './styles.module.scss'
import { useHistory } from 'react-router-dom'
import { EnvironmentContext } from '../../providers/EnvironmentProvider'
import { GetNewEventDto } from '../../../api/generated'

const EventCaption: FC = () => {
  const { env } = useContext<any>(EnvironmentContext)
  const [data, setData] = useState<GetNewEventDto>()

  useEffect(() => {
    if (env.events) {
      let longBannerEvent = env.events.filter(
        (e: GetNewEventDto) =>
          e.isLongFloatingBannerActive === true &&
          e.isEventActive === true &&
          e.templateName === 'Offer with cars preview',
      )[0]
      setData(longBannerEvent)
    }
  }, [env])

  const history = useHistory()
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  const listenToScroll = () => {
    let heightToHideFrom = 200
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    if (winScroll > heightToHideFrom) {
      isVisible && setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }

  return (
    <>
      {isVisible && data && (
        <div className={styles.eventCaption}>
          <div className={styles.eventWrapper}>
            <div
              className={styles.banner}
              onClick={(e) => {
                e.preventDefault()
                history.push(`/sales-offer/${data.symbolCode}`)
                //history.push('/electric-car-offer')
              }}
            >
              <picture className={styles.pic}>
                <source media='(max-width: 576px)' srcSet={data.mobileFloatingBanner ?? ''} />
                <img className={styles.img} src={data.longFloatingBanner ?? ''} alt={data.articleTitle ?? ''} />
              </picture>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default EventCaption
