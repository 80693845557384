/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Undefined
 *
 * 1 = Hyundai
 *
 * 2 = Vauxhall
 *
 * 3 = Suzuki
 *
 * 4 = Mitsubishi
 *
 * 5 = Chevrolet
 *
 * 6 = NonFranchise
 *
 * 7 = Isuzu
 *
 * 8 = Daewoo
 *
 * 9 = Nissan
 *
 * 10 = Skoda
 *
 * 11 = Ford
 */
export enum Manufacturer {
  Undefined = 0,
  Hyundai = 1,
  Vauxhall = 2,
  Suzuki = 3,
  Mitsubishi = 4,
  Chevrolet = 5,
  NonFranchise = 6,
  Isuzu = 7,
  Daewoo = 8,
  Nissan = 9,
  Skoda = 10,
  Ford = 11,
}
