import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './utils/store'
import './custom.css'
import EnvironmentProvider from './components/providers/EnvironmentProvider'
import { QueryClient, QueryClientProvider } from 'react-query'
import { OpenAPI } from './api/generated'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

OpenAPI.BASE = process.env.REACT_APP_API_BASE_URL?.slice(0, -1) ?? ''
OpenAPI.ENCODE_PATH = encodeURIComponent

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename='/'>
        <EnvironmentProvider>
          <App />
        </EnvironmentProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>,
)
