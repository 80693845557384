import { FC } from 'react'
import { useAppDispatch, useAppSelector } from '../../../common/hooks/reduxHooks'
import { contactUsActions } from '../../../../utils/slices/contactUsChatSlice'

const ToggleButton: FC = () => {
  const isActionFormExpanded = useAppSelector((state) => state.contactUs.isActionFormExpanded)
  const isChatExpanded = useAppSelector((state) => state.contactUs.isChatExpanded)
  const dispatch = useAppDispatch()
  return (
    <button
      className='btn btn--red contact-us__toggle-btn'
      onClick={() => {
        if (isChatExpanded) dispatch(contactUsActions.setChatExpanded(false))
        else dispatch(contactUsActions.setExpanded(!isActionFormExpanded))
      }}
    >
      <span className='contact-us__toggle-icon'>
        {isActionFormExpanded ? (
          <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z'
              fill='white'
            ></path>
          </svg>
        ) : (
          <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M16.2 0H1.8C0.81 0 0.00899999 0.81 0.00899999 1.8L0 18L3.6 14.4H16.2C17.19 14.4 18 13.59 18 12.6V1.8C18 0.81 17.19 0 16.2 0ZM6.3 8.1H4.5V6.3H6.3V8.1ZM9.9 8.1H8.1V6.3H9.9V8.1ZM13.5 8.1H11.7V6.3H13.5V8.1Z'
              fill='white'
            ></path>
          </svg>
        )}
      </span>
      <span>Chat or text with Us</span>
    </button>
  )
}

export default ToggleButton
