import { FC, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../common/hooks/reduxHooks'
import { contactUsActions } from '../../../../utils/slices/contactUsChatSlice'
import Scrollbars from 'react-custom-scrollbars-2'
import { fetchInitConversation, fetchMessages } from '../../../../utils/actions/contactUsChatActions'
import InBoundMessage from './InBoundMessage'
import OutBoundMessage from './OutBoundMessage'
import { chatApi } from '../../../../api/chat'
import moment from 'moment'
import { TwilioMessage } from '../../../../models/contactUs/TwilioMessage'
import Form from './Form'
import { getStockCarId } from '../../../../utils/functions'
import { InitConversation } from '../../../../models/contactUs/InitConversation'

const Chat: FC = () => {
  const { conversationId, userId, isChatExpanded, startedDate, startedTime, conversationClosed } = useAppSelector(
    (state) => state.contactUs,
  )

  const messagesList = useAppSelector((state) => state.contactUs.messages)
  const { connectionClosed } = useAppSelector((state) => state.environment)
  const [message, setMessage] = useState<string>('')

  const dispatch = useAppDispatch()
  const chatRef = useRef<any>()

  const addMessage = (newMessage: TwilioMessage) => {
    dispatch(contactUsActions.addMessage(newMessage))
  }
  useEffect(() => {
    if (!conversationId && isChatExpanded) {
      const model: InitConversation = {
        conversationType: 0,
      }
      dispatch(fetchInitConversation(model))
    }
  }, [conversationId, isChatExpanded])

  useEffect(() => {
    if (conversationId) {
      dispatch(fetchMessages())
    }
  }, [conversationId])

  useEffect(() => {
    if (messagesList && messagesList.length && isChatExpanded) {
      chatRef?.current?.scrollToBottom()
    }
  }, [messagesList])

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    setMessage(e.target.value)
  }

  const handleSendMessage = () => {
    if (conversationId && message && userId) {
      setMessage('')

      let stockId = ''
      if (window.location.pathname.includes('/stock-car/')) {
        const id = window.location.pathname.split('/').pop()
        stockId = getStockCarId(id)
      }

      const formData = new FormData()
      formData.append('ConversationSid', conversationId)
      formData.append('Message', message)
      formData.append('CustomerUid', userId)
      if (stockId) formData.append('StockId', stockId)
      formData.append('ClientCurrentUrl', window.location.href)
      chatApi.fetchSendMessage(formData).then(() => {
        const newMessage: TwilioMessage = {
          message: message,
          sender: 'Visitor',
          dateSent: moment().format('YYYY-MM-DDTHH:mm:ss'),
          type: 1,
          typeName: 'In',
          status: undefined,
          statusName: undefined,
          photoUrl: undefined,
          medias: undefined,
        }
        addMessage(newMessage)
      })
    }
  }

  return (
    <div className='chat contact-us__chat' style={isChatExpanded ? { display: 'block' } : { display: 'none' }}>
      <div className='chat__top'>
        <div className='chat__title'>
          <svg width='38' height='25' viewBox='0 0 38 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M15.6411 0L22.222 10.1024C25.2675 10.4196 28.2757 11.026 31.2058 11.9134C25.4435 11.2842 21.5649 11.3423 18.0864 11.7336L15.6411 7.62502L12.5387 12.8335C9.69073 13.6029 7.01493 14.79 4.83984 16.5667L15.6411 0Z'
              fill='#595958'
            />
            <path
              d='M37.2175 14.6895C33.212 14.4577 29.1943 14.5461 25.2029 14.9538L31.2963 24.0119H25.9685L20.4686 15.6809C8.28174 18.1583 5.33306 23.9378 5.33306 23.9378L0 24.0119C10.1257 7.27332 37.2175 14.6974 37.2175 14.6974'
              fill='#AC1919'
            />
          </svg>
          <span>Advance</span>
        </div>
        <div
          className='chat__close'
          onClick={(e) => {
            e.preventDefault()
            dispatch(contactUsActions.setChatExpanded(false))
          }}
        >
          <svg width='14' height='14' viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'>
            <path d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z' />
          </svg>
        </div>
      </div>

      <div className='chat__main'>
        <div className='chat__scroll'>
          <Scrollbars
            ref={chatRef}
            //autoHeight
            //autoHide
            //autoHeightMin='390px'
            //autoHeightMax='390px'
            // style={{ width: '100%' }}
            renderTrackHorizontal={(props) => (
              <div {...props} className='track-horizontal' style={{ display: 'none' }} />
            )}
            renderView={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  paddingRight: '15px',
                  paddingBottom: '15px',
                  overflowX: 'hidden',
                }}
              />
            )}
            renderThumbVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  width: '3px',
                  borderRadius: '4px',
                  backgroundColor: '#d4141c',
                }}
              />
            )}
          >
            {connectionClosed || conversationClosed ? (
              <div className='chat__started'>
                <span>Connection closed. Try refreshing this page to restart the connection.</span>
              </div>
            ) : (
              <div className='chat__started'>
                <div className='chat__started-title'>{conversationId ? 'Chat started' : 'Loading...'}</div>
                <div className='chat__started-date'>
                  {startedDate && <time>{startedDate}</time>}, {startedTime && <time>{startedTime}</time>}
                </div>
              </div>
            )}

            {/* <div className='chat__message'>
              <div className='chat__message-bubble'>Good Evening. I have a question. Can you help me?</div>
              <div className='chat__message-info'>
                <span className='chat__author'>You</span>
                <time className='chat__date'>09:22</time>
              </div>
            </div>
            <div className='chat__message__label'>
              <div className='chat__message__label__line'></div>
              <div className='chat__message__label__text'>Today</div>
              <div className='chat__message__label__line'></div>
            </div>
            <div className='chat__message chat__message--user'>
              <div className='chat__avatar'>
                <img src='images/avatar-chat.jpg' alt='' />
              </div>
              <div className='chat__message-wrapper'>
                <div className='chat__message-bubble'>Good morning, how can I help you?</div>
                <div className='chat__message-info'>
                  <span className='chat__author'>Oliver</span>
                  <time className='chat__date'>09:26</time>
                </div>
              </div>
            </div>
            <div className='chat__message__label'>
              <div className='chat__message__label__line'></div>
              <div className='chat__message__label__text'>17 Nov 2024</div>
              <div className='chat__message__label__line'></div>
            </div> */}
            {/* <div className='chat__message'>
              <div className='chat__message-bubble'>
                Vivamus tellus dolor, aliquet et nulla sed, consequat mollis turpis.
              </div>
              <div className='chat__message__file'>
                <div className='chat__message__file__item'>
                  <svg width='11' height='14' viewBox='0 0 11 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M1.375 14C0.996875 14 0.673177 13.8629 0.403906 13.5887C0.134635 13.3146 0 12.985 0 12.6V1.4C0 1.015 0.134635 0.685417 0.403906 0.41125C0.673177 0.137083 0.996875 0 1.375 0H6.30781C6.49115 0 6.66589 0.035 6.83203 0.105C6.99818 0.175 7.14427 0.274167 7.27031 0.4025L10.6047 3.7975C10.7307 3.92583 10.8281 4.07458 10.8969 4.24375C10.9656 4.41292 11 4.59083 11 4.7775V12.6C11 12.985 10.8654 13.3146 10.5961 13.5887C10.3268 13.8629 10.0031 14 9.625 14H1.375ZM6.1875 4.2C6.1875 4.39833 6.25339 4.56458 6.38516 4.69875C6.51693 4.83292 6.68021 4.9 6.875 4.9H9.625L6.1875 1.4V4.2Z'
                      fill='#CE181F'
                    />
                  </svg>
                  <div className='chat__message__file__item__text'>photo-29032024.jpg</div>
                </div>
                <div className='chat__message__file__item'>
                  <svg width='11' height='14' viewBox='0 0 11 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M1.375 14C0.996875 14 0.673177 13.8629 0.403906 13.5887C0.134635 13.3146 0 12.985 0 12.6V1.4C0 1.015 0.134635 0.685417 0.403906 0.41125C0.673177 0.137083 0.996875 0 1.375 0H6.30781C6.49115 0 6.66589 0.035 6.83203 0.105C6.99818 0.175 7.14427 0.274167 7.27031 0.4025L10.6047 3.7975C10.7307 3.92583 10.8281 4.07458 10.8969 4.24375C10.9656 4.41292 11 4.59083 11 4.7775V12.6C11 12.985 10.8654 13.3146 10.5961 13.5887C10.3268 13.8629 10.0031 14 9.625 14H1.375ZM6.1875 4.2C6.1875 4.39833 6.25339 4.56458 6.38516 4.69875C6.51693 4.83292 6.68021 4.9 6.875 4.9H9.625L6.1875 1.4V4.2Z'
                      fill='#CE181F'
                    />
                  </svg>
                  <div className='chat__message__file__item__text'>long-name-2903202...4.jpg</div>
                </div>
                <div className='chat__message__file__item'>
                  <svg width='11' height='14' viewBox='0 0 11 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M1.375 14C0.996875 14 0.673177 13.8629 0.403906 13.5887C0.134635 13.3146 0 12.985 0 12.6V1.4C0 1.015 0.134635 0.685417 0.403906 0.41125C0.673177 0.137083 0.996875 0 1.375 0H6.30781C6.49115 0 6.66589 0.035 6.83203 0.105C6.99818 0.175 7.14427 0.274167 7.27031 0.4025L10.6047 3.7975C10.7307 3.92583 10.8281 4.07458 10.8969 4.24375C10.9656 4.41292 11 4.59083 11 4.7775V12.6C11 12.985 10.8654 13.3146 10.5961 13.5887C10.3268 13.8629 10.0031 14 9.625 14H1.375ZM6.1875 4.2C6.1875 4.39833 6.25339 4.56458 6.38516 4.69875C6.51693 4.83292 6.68021 4.9 6.875 4.9H9.625L6.1875 1.4V4.2Z'
                      fill='#CE181F'
                    />
                  </svg>
                  <div className='chat__message__file__item__text'>image.png</div>
                </div>
              </div>
              <div className='chat__message-info'>
                <span className='chat__author'>You</span>
                <time className='chat__date'>09:31</time>
              </div>
            </div> */}
            {/* <div className='chat__message__robot'>
              <div className='chat__message__robot__item'>I’m interested in buying a car</div>
              <div className='chat__message__robot__item'>I’d like to sell my car</div>
              <div className='chat__message__robot__item'>I have a question</div>
              <div className='chat__message__robot__item'>I’d like to book in for a service or a repair</div>
            </div> */}
            {/* <div className='chat__message chat__message--user'>
              <div className='chat__avatar'>
                <img src='images/avatar-chat.jpg' alt='' />
              </div>
              <div className='chat__message-wrapper'>
                <div className='chat__message-bubble'>
                  Quisque varius lacus neque, eget ullamcorper nunc laoreet id. Quisque eget bibendum magna. Duis
                  elementum convallis
                </div>
                <div className='chat__message-info'>
                  <span className='chat__author'>Oliver</span>
                  <time className='chat__date'>09:26</time>
                </div>
              </div>
            </div> */}

            {!conversationClosed &&
              !connectionClosed &&
              messagesList?.map((group) => (
                <>
                  <div className='chat__message__label'>
                    <div className='chat__message__label__line'></div>
                    <div className='chat__message__label__text'>{moment(group.date).format('DD MMM yyyy')}</div>
                    <div className='chat__message__label__line'></div>
                  </div>
                  {group.messages.map((message) => {
                    if (message.type === 2) return <InBoundMessage key={message.id} message={message} />
                    else return <OutBoundMessage key={message.id} message={message} />
                  })}
                </>
              ))}

            {/* <div className='chat__message'>
              <div className='chat__message-bubble'>Good Evening. I have a question. Can you help me?</div>
              <div className='chat__message-info'>
                <span className='chat__author'>You</span>
                <time className='chat__date'>09:22</time>
              </div>
            </div>

            <div className='chat__message chat__message--user'>
              <div className='chat__avatar'>
                <img src='images/avatar-chat.jpg' alt='' />
              </div>
              <div className='chat__message-wrapper'>
                <div className='chat__message-bubble'>Good morning, how can I help you?</div>
                <div className='chat__message-info'>
                  <span className='chat__author'>Oliver</span>
                  <time className='chat__date'>09:26</time>
                </div>
              </div>
            </div>

            <div className='chat__message'>
              <div className='chat__message-bubble'>
                Vivamus tellus dolor, aliquet et nulla sed, consequat mollis turpis.
              </div>
              <div className='chat__message-info'>
                <span className='chat__author'>You</span>
                <time className='chat__date'>09:31</time>
              </div>
            </div> */}
          </Scrollbars>
        </div>
      </div>

      <Form message={message} handleMessageChange={handleMessageChange} handleSendMessage={handleSendMessage} />
    </div>
  )
}

export default Chat
