import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ReservationModel, ReservationState } from '../../models/reservationOffer/ReservationState'

const initialModel: ReservationState = {
  reservation: {
    price: 0,
    imageUrl: '',
    colorName: '',
    carName: '',
    stockNo: '',
    cancelUrl: '',
    isNew: false,
    stockId: undefined,
    reservationModel: undefined,
  },
}

const reservationSlice = createSlice({
  name: 'reservationSlice',
  initialState: initialModel,
  reducers: {
    setReservation(state, action: PayloadAction<ReservationModel>) {
      state.reservation = action.payload
    },
  },
})

export default reservationSlice
export const { reducer: reservationReducer, actions: reservationActions } = reservationSlice
