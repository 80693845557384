import { FC, KeyboardEvent } from 'react'
import styles from './styles.module.scss'
import { useAppSelector } from '../../../common/hooks/reduxHooks'

interface IProps {
  message: string
  handleMessageChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  handleSendMessage: () => void
}

const Form: FC<IProps> = ({ message, handleMessageChange, handleSendMessage }) => {
  const connectionClosed = useAppSelector((state) => state.environment.connectionClosed)
  const conversationId = useAppSelector((state) => state.contactUs.conversationId)

  const handleKeyDown = (keyEvent: KeyboardEvent<HTMLElement>) => {
    if (keyEvent.keyCode == 13 && keyEvent.shiftKey == false) {
      keyEvent.preventDefault()
      handleSendMessage()
    }
  }

  return (
    <>
      {!connectionClosed && conversationId && (
        <form action='' method='post' className='chat__bottom form-block'>
          <div className={`input-block ${styles.chatBottom}`}>
            <label>
              <textarea
                rows={2}
                placeholder='Type your message'
                value={message}
                onChange={handleMessageChange}
                onKeyDown={handleKeyDown}
              />
            </label>
            {/* <label className='chat__file'>
          <input type='file' />
          <svg width='18' height='21' viewBox='0 0 18 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M16.5726 9.58581L8.44088 17.7175C6.87817 19.2802 4.34673 19.2802 2.78402 17.7175C1.22132 16.1548 1.22132 13.6234 2.78402 12.0607L11.6229 3.22185C12.5987 2.24604 14.1826 2.24604 15.1584 3.22185C16.1342 4.19766 16.1342 5.78158 15.1584 6.75738L7.73377 14.182C7.34486 14.5709 6.70847 14.5709 6.31956 14.182C5.93065 13.7931 5.93065 13.1567 6.31956 12.7678L13.0371 6.05028L11.9764 4.98962L5.2589 11.7071C4.28309 12.6829 4.28309 14.2669 5.2589 15.2427C6.2347 16.2185 7.81862 16.2185 8.79443 15.2427L16.2191 7.81804C17.7818 6.25534 17.7818 3.7239 16.2191 2.16119C14.6563 0.598485 12.1249 0.598485 10.5622 2.16119L1.72336 11C-0.426242 13.1496 -0.426241 16.6286 1.72336 18.7782C3.87297 20.9278 7.35193 20.9278 9.50154 18.7782L17.6333 10.6465L16.5726 9.58581Z'
              fill='#B2B9BC'
            />
          </svg>
        </label> */}
            <label
              className='chat__send-btn'
              onClick={(e) => {
                e.preventDefault()
                handleSendMessage()
              }}
            >
              <input type='submit' />
              <svg width='21' height='18' viewBox='0 0 21 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M1.68723 17.9016C1.28551 18.0615 0.903874 18.0265 0.542324 17.7967C0.180775 17.5669 0 17.2323 0 16.7927V11.3977L9.64132 9L0 6.60225V1.20733C0 0.76774 0.180775 0.433054 0.542324 0.20327C0.903874 -0.0265135 1.28551 -0.0614807 1.68723 0.0983691L20.2468 7.89104C20.7489 8.11084 21 8.48049 21 9C21 9.51951 20.7489 9.88916 20.2468 10.109L1.68723 17.9016Z'
                  fill='#CE181F'
                />
              </svg>
            </label>
          </div>
        </form>
      )}
    </>
  )
}

export default Form
