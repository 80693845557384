import { FC, useEffect } from 'react'
import styles from './styles.module.scss'
import ToggleButton from './components/toggleButton/ToggleButton'
import '../../styles/Chat.scss'
import { useAppSelector } from '../common/hooks/reduxHooks'
import Chat from './components/chat/Chat'

const VirtualAssistant: FC = () => {
  const isChatExpanded = useAppSelector<any>((state) => state.contactUs.isVaChatExpanded)

  useEffect(() => {
    if (isChatExpanded) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isChatExpanded])

  return (
    <>
      <div className={styles.VirtualAssistant}>
        <ToggleButton />
      </div>
      <Chat />
    </>
  )
}

export default VirtualAssistant
