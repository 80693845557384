import { FC, KeyboardEvent } from 'react'
import styles from './styles.module.scss'
import { useAppSelector } from '../../../common/hooks/reduxHooks'

interface IProps {
  message: string
  handleMessageChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  handleSendMessage: () => void
}

const Form: FC<IProps> = ({ message, handleMessageChange, handleSendMessage }) => {
  const connectionClosed = useAppSelector((state) => state.environment.connectionClosed)
  const conversationId = useAppSelector((state) => state.contactUs.vaConversationId)

  const handleKeyDown = (keyEvent: KeyboardEvent<HTMLElement>) => {
    if (keyEvent.keyCode == 13 && keyEvent.shiftKey == false) {
      keyEvent.preventDefault()
      handleSendMessage()
    }
  }

  return (
    <>
      {!connectionClosed && conversationId && (
        <form action='' method='post' className='chat__bottom form-block'>
          <div className={`input-block ${styles.chatBottom}`}>
            <label>
              <textarea
                rows={2}
                placeholder='Type your message'
                value={message}
                onChange={handleMessageChange}
                onKeyDown={handleKeyDown}
              />
            </label>
            <label
              className='chat__send-btn'
              onClick={(e) => {
                e.preventDefault()
                handleSendMessage()
              }}
            >
              <input type='submit' />
              <svg width='21' height='18' viewBox='0 0 21 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M1.68723 17.9016C1.28551 18.0615 0.903874 18.0265 0.542324 17.7967C0.180775 17.5669 0 17.2323 0 16.7927V11.3977L9.64132 9L0 6.60225V1.20733C0 0.76774 0.180775 0.433054 0.542324 0.20327C0.903874 -0.0265135 1.28551 -0.0614807 1.68723 0.0983691L20.2468 7.89104C20.7489 8.11084 21 8.48049 21 9C21 9.51951 20.7489 9.88916 20.2468 10.109L1.68723 17.9016Z'
                  fill='#CE181F'
                />
              </svg>
            </label>
          </div>
        </form>
      )}
    </>
  )
}

export default Form
