import { FC } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  onClick?: any
}

const NewArrivals: FC<Props> = ({ onClick }) => {
  return (
    <>
      <li
        onClick={() => {
          if (onClick) onClick()
        }}
      >
        <Link
          to={{
            pathname: '/astra-griffin',
          }}
        >
          Astra Griffin
        </Link>
      </li>
      <li
        onClick={() => {
          if (onClick) onClick()
        }}
      >
        <Link
          to={{
            pathname: '/corsa-hybrid',
          }}
        >
          Corsa Hybrid
        </Link>
      </li>
      <li
        onClick={() => {
          if (onClick) onClick()
        }}
      >
        <Link
          to={{
            pathname: '/yes-corsa',
          }}
        >
          Corsa Yes Edition
        </Link>
      </li>
      <li
        onClick={() => {
          if (onClick) onClick()
        }}
      >
        <Link
          to={{
            pathname: '/mokka-griffin',
          }}
        >
          Mokka Griffin
        </Link>
      </li>
      <li>
        <Link
          to={{
            pathname: '/new-grandland',
          }}
        >
          New Grandland
        </Link>
      </li>
      {/* <li>
        <Link
          to={{
            pathname: '/new-corsa',
          }}
        >
          New Corsa
        </Link>
      </li>
      <li>
        <Link
          to={{
            pathname: '/grandland-hybrid',
          }}
        >
          New Grandland Hybrid
        </Link>
      </li> */}

      {/* <li>
        <Link
          to={{
            pathname: 'new-frontera',
          }}
        >
          New Frontera
        </Link>
      </li> */}
    </>
  )
}

export default NewArrivals
