/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Значения для выпадающего списка фильтра типа кузова.
 * Данное перечисление исключительно для представления.
 * Один вариант может соответствовать многим типам кузова
 *
 * 0 = All
 *
 * 1 = Coupe
 *
 * 2 = Estate
 *
 * 3 = Hatchback
 *
 * 4 = MPV
 *
 * 5 = SUV
 *
 * 6 = Unleasted
 */
export enum CarBodyTypeFilter {
  All = 0,
  Coupe = 1,
  Estate = 2,
  Hatchback = 3,
  MPV = 4,
  SUV = 5,
  Unleasted = 6,
}
