import { FC, useEffect, useRef } from 'react'
import Lottie from 'lottie-react'
//import cropped from '../../../../lotties/cropped.json'
import wave from '../../../../lotties/wave.json'
import { useAppDispatch } from '../../../common/hooks/reduxHooks'
import { contactUsActions } from '../../../../utils/slices/contactUsChatSlice'

const LottieAnimation: FC = () => {
  const lottieRef = useRef<any>()
  const dispatch = useAppDispatch()
  //const [intoIsOver, setIntoIsOver] = useState<boolean>(false)

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      lottieRef?.current?.stop()
      lottieRef?.current?.play()
      //lottieRef?.current?.goToAndPlay(2100, false)
    }, 10000)

    return () => {
      clearInterval(countdownInterval)
      dispatch(contactUsActions.setVaConversationId(undefined))
      dispatch(contactUsActions.clearVaConversationMessages())
    }
  }, [])

  return (
    <>
      <Lottie lottieRef={lottieRef} loop={false} animationData={wave} />
      {/* <Lottie
        autoplay={true}
        loop={false}
        animationData={cropped}
        style={!intoIsOver ? {} : { display: 'none' }}
        onComplete={() => {
          setIntoIsOver(true)
        }}
      /> */}
    </>
  )
}

export default LottieAnimation

//<Lottie style={animationStyle} options={defaultOptions} height={100} width={100} isStopped={isStopped} onComplete={()=>{}}/>
