import { configureStore } from '@reduxjs/toolkit'
import { reducer as mainReducer, actions as sliceActions } from './reducer'
//import chatSlice from '../components/twilioChat/store/chat.reducer'
//import configSlice from '../components/twilioChat/store/config.reducer'
//import notificationSlice from '../components/twilioChat/store/notification.reducer'
//import sessionSlice from '../components/twilioChat/store/session.reducer'
import { newCarDetailsApi } from '../services/NewCarDetailsService'
import buildDealSlice from './slices/buildDealSlice'
import applyFinanceSlice from './slices/applyFinanceSlice'
import reservationSlice from './slices/reservationSlice'
import contentLogSlice from './slices/contentLogSlice'
import contactUsSlice from './slices/contactUsChatSlice'
import signalRMiddleware from './middlewares/signalRMiddleware'
import envSlice from './slices/envSlice'

const rootReducer = {
  global: mainReducer,
  //chat: chatSlice.reducer,
  //config: configSlice.reducer,
  //notifications: notificationSlice.reducer,
  //session: sessionSlice.reducer,
  buildDeal: buildDealSlice,
  reservation: reservationSlice.reducer,
  applyFinance: applyFinanceSlice,
  contentLog: contentLogSlice.reducer,
  contactUs: contactUsSlice.reducer,
  environment: envSlice.reducer,
  [newCarDetailsApi.reducerPath]: newCarDetailsApi.reducer,
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat([newCarDetailsApi.middleware, signalRMiddleware])
  },
})

export const actions = {}

Object.keys(sliceActions).forEach((key) => {
  actions[key] = (props) => {
    store.dispatch(sliceActions[key](props))
  }
})

actions.dispatch = (action) => {
  store.dispatch(action)
}
