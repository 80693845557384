/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = TextWithVideoOnSide
 *
 * 1 = TileParagraphWithImageOnSide
 *
 * 2 = SimpleTextWithImageOnSide
 *
 * 3 = TwoColumnOfTextAndThreeImageBlock
 */
export enum ContentLogSectionType {
  TextWithVideoOnSide = 0,
  TileParagraphWithImageOnSide = 1,
  SimpleTextWithImageOnSide = 2,
  TwoColumnOfTextAndThreeImageBlock = 3,
}
