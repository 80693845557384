/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Тип транспортного средства
 *
 * 0 = Undefined
 *
 * 1 = Bike (Motorcycle)
 *
 * 2 = Car
 *
 * 3 = Comp (Component vehicle)
 *
 * 4 = Hgv (Heavy Goods Vehicle)
 *
 * 5 = Van (Light Commercial Vehicle)
 */
export enum VehicleTypes {
  Undefined = 0,
  /**
   * Motorcycle
   */
  Bike = 1,
  Car = 2,
  /**
   * Component vehicle
   */
  Comp = 3,
  /**
   * Heavy Goods Vehicle
   */
  Hgv = 4,
  /**
   * Light Commercial Vehicle
   */
  Van = 5,
}
