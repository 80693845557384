import { fetchApi } from './fetch'
const URL_SAVE = 'SendEmailByTemplate'
const URL_NOTIFICATION = 'SendEmail'

const fetchPostEmail = (data: any) => fetchApi(URL_SAVE, 'POST', data)
const fetchPostNotification = (data: any) => fetchApi(URL_NOTIFICATION, 'POST', data)

export const emailApi = {
  fetchPostEmail,
  fetchPostNotification,
}
