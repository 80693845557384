/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Any
 *
 * 1 = Diesel
 *
 * 2 = Petrol
 *
 * 3 = Electric
 *
 * 4 = Hybrid
 */
export enum FuelTypeFilter {
  Any = 0,
  Diesel = 1,
  Petrol = 2,
  Electric = 3,
  Hybrid = 4,
}
