import find from 'lodash/find'
import { max } from 'mathjs'

const DEFAULT_DEPOSIT = 500

export const getCarPhotoUrl = (stockPhotoUrl) => {
  if (stockPhotoUrl) return stockPhotoUrl
  else return `/Img/placeholder.png`
}

export const getCarPrice = (data, selectedProgram) => {
  // TODO vat
  // final leese proramm

  let fda = selectedProgram.fda ? selectedProgram.fda : 0
  let flBonus = selectedProgram.flBonus ? selectedProgram.flBonus : 0
  let price = data.price - fda
  if (selectedProgram.finTypeId == 7) price = price - flBonus
  return price
}

export const getDefaultDeposit = (program) => {
  return Math.round(program?.defaultDeposit ? program.defaultDeposit : DEFAULT_DEPOSIT)
}

export const getDefaultProgram = (finData) => {
  let program = find(finData, { isDefault: true })
  program = program ?? find(finData, { finTypeId: 7 })
  program = program ?? find(finData, { finTypeId: 1 })
  program = program ?? find(finData, { finTypeId: 2 })
  return program
}

export const getMaxDeposit = (data, selectedProgram) => {
  return selectedProgram.finTypeId == 1
    ? data.price - (data.isVan ? selectedProgram.fda * 1.2 : selectedProgram.fda) - 1500
    : data.price - selectedProgram.fda - max(1500, selectedProgram.rfv)
}

export const getMaximumDeposit = (variantPrice, isVan, selectedProgram) => {
  return Math.round(
    selectedProgram.finTypeId == 1
      ? variantPrice - (isVan ? selectedProgram.fda * 1.2 : selectedProgram.fda) - 1500
      : variantPrice - selectedProgram.fda - max(1500, selectedProgram.rfv),
  )
}

export const getNewCarPrice = (data) => {
  if (data.variant.prices.cashPrice && data.variant.isVan) {
    return data.variant.prices.cashPrice - (data.variant.prices.discount ? data.variant.prices.discount : 0)
  }
  return data.variant.prices.price - (data.variant.prices.discount ? data.variant.prices.discount : 0)
  // - (data.finData ? data.finData[0].fda : 0);
  // return Math.round(price / 50) * 50;
}

export const getNewCarPriceByPriceAndDiscount = (price, discount) => {
  return price - (discount ? discount : 0)
  // - (data.finData ? data.finData[0].fda : 0);
  // return Math.round(price / 50) * 50;
}

export const getNewCarPriceByProgram = (price, discount, program) => {
  return price - (discount ? discount : 0) - (program ? program.fda : 0)
}

export const getNewCarPriceByProgramAndConfiguration = (
  price,
  discount,
  congigurationPrice,
  program,
  vat,
  isVan,
  skipFda = false,
  totalPrice = 0,
) => {
  if (!program) return 0

  if (totalPrice > 0) {
    if (isVan && (program.finTypeId == 9 || program.finTypeId == 7)) {
      return totalPrice - (skipFda ? 0 : program.fda) - (vat ? vat : 0) - (program.finTypeId == 7 ? program.flBonus : 0)
    } else {
      return totalPrice - (skipFda ? 0 : program.fda)
    }
  }

  if (program.price && program.price > 0) {
    let programPrice = program.price + congigurationPrice - (skipFda ? 0 : program.fda)
    if (program.finTypeId == 7) {
      programPrice = programPrice - program.flBonus
    }

    return programPrice
  }

  if (isVan && (program.finTypeId == 9 || program.finTypeId == 7)) {
    return (
      price -
      (discount ? discount : 0) -
      (skipFda ? 0 : program.fda) -
      (vat ? vat : 0) +
      congigurationPrice -
      (program.finTypeId == 7 ? program.flBonus : 0)
    )
  } else {
    return price - (discount ? discount : 0) - (skipFda ? 0 : program.fda) + congigurationPrice
  }
}

export const getNewCarConfigurationPrice = (configuration, program, isVan) => {
  if (program == null) return 0

  let featuresPrice = 0
  let colorPrice = 0

  if (configuration) {
    if (isVan && (program.finTypeId == 9 || program.finTypeId == 7)) {
      featuresPrice = configuration.optionalFeatures.reduce((acc, obj) => {
        return acc + Math.round(obj.price.price - obj.price.vat)
      }, 0)
      colorPrice =
        configuration.color && configuration.color.price
          ? Math.round(configuration.color.price.price - configuration.color.price.vat)
          : 0
    } else {
      featuresPrice = configuration.optionalFeatures.reduce((acc, obj) => {
        return acc + Math.round(obj.price.price)
      }, 0)
      colorPrice = configuration.color && configuration.color.price ? Math.round(configuration.color.price.price) : 0
    }
  }
  return featuresPrice + colorPrice
}

// без учета discont
export const getNewCarPriceWithConfiguration = (data, configuration, program, varClassId) => {
  if (program == null) return 0

  let featuresPrice = 0
  let carPrice = 0
  let colorPrice = 0

  if (program.price && program.price > 0) {
    carPrice = program.price
  } else {
    if (varClassId == 2 && (program.finTypeId == 9 || program.finTypeId == 7)) {
      carPrice = Math.round(data.variant.prices.price - data.variant.prices.vat)
    } else {
      carPrice = data.variant.prices.price
    }
  }

  if (configuration) {
    if (varClassId == 2 && (program.finTypeId == 9 || program.finTypeId == 7)) {
      featuresPrice = configuration.optionalFeatures.reduce((acc, obj) => {
        return acc + Math.round(obj.price.price - obj.price.vat)
      }, 0)
      colorPrice =
        configuration.color && configuration.color.price
          ? Math.round(configuration.color.price.price - configuration.color.price.vat)
          : 0
    } else {
      featuresPrice = configuration.optionalFeatures.reduce((acc, obj) => {
        return acc + Math.round(obj.price.price)
      }, 0)
      colorPrice = configuration.color && configuration.color.price ? Math.round(configuration.color.price.price) : 0
    }
  }
  return featuresPrice + carPrice + colorPrice
}

// с учетом discont
export const getNewCarTotalPrice = (data, configuration) => {
  let featuresPrice = 0
  let carPrice = getNewCarPrice(data)

  let colorPrice = 0

  if (configuration) {
    featuresPrice = configuration.optionalFeatures.reduce((acc, obj) => {
      return acc + Math.round(obj.price)
    }, 0)
    colorPrice =
      configuration.color && configuration.color.price && configuration.color.price > 0
        ? Math.round(configuration.color.price)
        : 0
  }

  return featuresPrice + carPrice + colorPrice
}
// var depositMax = Model.IsHP || Model.SelectedType == FinanceTypeEnum.x4x4
// 		 ? Model.Price - (Model.IsVan ? Model.Ra * (decimal)1.2 : Model.Ra) - 1500
// 		 : Model.Price - (Model.IsVan ? Model.Ra : Model.Fda) - Math.Max(1500, Model.Rfv);

export const GetFriendlyUrl = (carName, stockId, carDescription) => {
  if (carDescription) {
    const carFullName = `${carName.trim()} ${carDescription.trim()}`
    return `${carFullName
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .replaceAll(' ', '-')
      .replaceAll('/', '')}-${stockId}`
  }
  return `${carName
    .trim()
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replaceAll(' ', '-')
    .replaceAll('/', '')}-${stockId}`
}

export const GetExternalStockFriendlyUrl = (id, carDescription) => {
  const carFullName = `${carDescription}`
  return `${carFullName
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replaceAll(' ', '-')
    .replaceAll('/', '')}-${id}`
}

export const GetDeposit = (program) => {
  let rtn = 0
  if (program) {
    if (program.minDeposit) {
      if (program.defaultDeposit && program.minDeposit > program.defaultDeposit) rtn = program.minDeposit
      else rtn = program.defaultDeposit ? program.defaultDeposit : DEFAULT_DEPOSIT
    } else return program.defaultDeposit ? program.defaultDeposit : DEFAULT_DEPOSIT
  } else rtn = 0

  return Math.round(rtn)
}

export const milesRange = (min, max) =>
  Array.from({ length: (max - min) / 1000 }, (_, i) => {
    return { value: min + i * 1000, displayText: `${min + i * 1000} miles` }
  })

export const possibleMilesRange = (arr) =>
  arr.map((e) => {
    return { value: e, displayText: `${e.toLocaleString()} miles` }
  })

export const possibleInitialRentalsRange = (arr) =>
  arr.map((e) => {
    return { value: e, displayText: `${e}` }
  })

export const getStockCarId = (id) => {
  try {
    const myArray = id.split('-')
    const calcId = myArray.slice(-1)
    if (calcId && calcId.length > 0) return calcId[0]
  } catch {
    return ''
  }
}
