const BASE_URL = process.env.REACT_APP_API_BASE_URL
const BASE_URL_CONNECT = process.env.REACT_APP_API_CONNECT_URL
const BASE_URL_GPT = process.env.REACT_APP_API_GPT_URL

//https://internalapi.advance-motors.co.uk/ - API

export const fetchApi = (url, method = 'GET', data = null) =>
  fetch(BASE_URL + url, {
    headers: {
      'Content-Type': 'application/json',
      accept: 'text/plain',
    },
    method,
    body: data ? JSON.stringify(data) : null,
  })
    .then(async (response) => {
      const isJson = response.headers.get('content-type')?.includes('application/json')
      let data = isJson ? await response.json() : ''

      const isText = response.headers.get('content-type')?.includes('text/plain')
      data = isText ? await response.text() : data

      if (!response.ok) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      return data
    })
    .catch((error) => {
      console.error('There was an error!', error)
      return null
    })

export const fetchFileApi = (url, method = 'GET', data = null) =>
  fetch(BASE_URL + url, {
    headers: {
      'Content-Type': 'application/json',
      accept: 'text/plain',
    },
    method,
    body: data ? JSON.stringify(data) : null,
  })
    .then(async (response) => {
      if (!response.ok) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      return response
    })
    .catch((error) => {
      console.error('There was an error!', error)
      return null
    })

export const fetchConnectApi = (url, method = 'GET', data = null) =>
  fetch(BASE_URL_CONNECT + url, {
    headers: {
      'Content-Type': 'application/json',
      accept: 'text/plain',
    },
    method,
    body: data ? JSON.stringify(data) : null,
  })
    .then(async (response) => {
      const isJson = response.headers.get('content-type')?.includes('application/json')
      let data = isJson ? await response.json() : ''

      const isText = response.headers.get('content-type')?.includes('text/plain')
      data = isText ? await response.text() : data

      if (!response.ok) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      return data
    })
    .catch((error) => {
      console.error('There was an error!', error)
      return null
    })

export const fetchGPTApi = (url, method = 'GET', data = null) =>
  fetch(BASE_URL_GPT + url, {
    headers: {
      'Content-Type': 'application/json',
      accept: 'text/plain',
    },
    method,
    body: data ? JSON.stringify(data) : null,
  })
    .then(async (response) => {
      const isJson = response.headers.get('content-type')?.includes('application/json')
      let data = isJson ? await response.json() : ''

      const isText = response.headers.get('content-type')?.includes('text/plain')
      data = isText ? await response.text() : data

      if (!response.ok) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      return data
    })
    .catch((error) => {
      console.error('There was an error!', error)
      return null
    })


export const postConnectApi = (url, method = 'POST', data) =>
  fetch(BASE_URL_CONNECT + url, {
    method,
    body: data,
  })
    .then(async (response) => {
      const isJson = response.headers.get('content-type')?.includes('application/json')
      let data = isJson ? await response.json() : ''

      const isText = response.headers.get('content-type')?.includes('text/plain')
      data = isText ? await response.text() : data

      if (!response.ok) {
        const error = (data && data.message) || response.status
        return Promise.reject(error)
      }

      return data
    })
    .catch((error) => {
      console.error('There was an error!', error)
      return null
    })


