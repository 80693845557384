import { FC, useEffect } from 'react'
//import SVG from 'react-inlinesvg'
//import { toAbsoluteUrl } from '../../../../utils/assets'
import useToggle from '../../../hooks/useToggle'
import { useAppDispatch, useAppSelector } from '../../../common/hooks/reduxHooks'
import { contactUsActions } from '../../../../utils/slices/contactUsChatSlice'
import LottieAnimation from '../chat/Lottie'

const ToggleButton: FC = () => {
  const { isVaChatExpanded, showVirtualAssistant } = useAppSelector((state) => state.contactUs)
  const dispatch = useAppDispatch()
  const [isOpened, toggleOpened] = useToggle()

  useEffect(() => {
    if (isOpened != isVaChatExpanded) dispatch(contactUsActions.setVaChatExpanded(isOpened))
  }, [isOpened])

  useEffect(() => {
    if (isOpened != isVaChatExpanded) toggleOpened()
  }, [isVaChatExpanded])

  return (
    <div
      style={{ width: '140px', display: showVirtualAssistant ? 'block' : 'none' }}
      onClick={(e) => {
        e.preventDefault()
        toggleOpened()
      }}
    >
      {showVirtualAssistant && <LottieAnimation />}
      {/* <Lottie style={animationStyle} options={defaultOptions} height={100} width={100} isStopped={isStopped} /> */}
      {/* <SVG src={toAbsoluteUrl('/images/virtual-assistant/virtualAssistant.svg')} /> */}
    </div>
  )
}

export default ToggleButton
