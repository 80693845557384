import { FC, useEffect } from 'react'
import ToggleButton from './components/toggleButton/ToggleButton'
import ActionForm from './components/actionForm/ActionForm'
import { useAppSelector } from '../common/hooks/reduxHooks'
import './ContactUs.scss'
import '../../styles/Chat.scss'
//import './Chat.scss'
import Chat from './components/chat/Chat'

const ChatWrapper: FC = () => {
  const { isChatExpanded, showChat } = useAppSelector((state) => state.contactUs)

  useEffect(() => {
    if (isChatExpanded) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isChatExpanded])

  return (
    <div className='contact-us' style={showChat ? { display: 'flex' } : { display: 'none' }}>
      <ActionForm />
      <Chat />
      <ToggleButton />
    </div>
  )
}

export default ChatWrapper
