/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Up
 *
 * 1 = Down
 */
export enum ContentLogMoveDir {
  Up = 0,
  Down = 1,
}
