/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = HalfContentWidth
 *
 * 1 = ThirdContentWidth
 */
export enum ContentLogImageSize {
  HalfContentWidth = 0,
  ThirdContentWidth = 1,
}
