import { FC, PropsWithChildren, memo } from "react";
import styles from './MenuLink.module.css'

interface IProps {
    title: string
};

const MenuLink: FC<PropsWithChildren<IProps>> = ({ title, children }) => {

    return (
        <>
            <div className={styles["header-link"]}>
                {title}
                <svg className={styles["header-link__svg"]} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                </svg>
            </div>
            <div className="header__submenu">
                <div className="header__submenu-list-wrapper">
                    {children}
                </div>
            </div>

        </>
    )
};

export default memo(MenuLink);