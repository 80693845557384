/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Used
 *
 * 1 = DeliveryMileage
 *
 * 2 = ExDemo
 *
 * 3 = New
 *
 * 4 = External
 */
export enum StockCarsTabFilter {
  Used = 0,
  DeliveryMileage = 1,
  ExDemo = 2,
  New = 3,
  External = 4,
}
