/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BranchType } from '../models/BranchType';
import type { CarBodyTypeFilter } from '../models/CarBodyTypeFilter';
import type { EngineSizeFilter } from '../models/EngineSizeFilter';
import type { ExternalStockFilteredResponseDTO } from '../models/ExternalStockFilteredResponseDTO';
import type { ExternalStockFilteredResponseDTOPagedListData } from '../models/ExternalStockFilteredResponseDTOPagedListData';
import type { FinanceTypeEnum } from '../models/FinanceTypeEnum';
import type { FuelTypeFilter } from '../models/FuelTypeFilter';
import type { MonthlyPaymentShortDto } from '../models/MonthlyPaymentShortDto';
import type { StockCarPageDTO } from '../models/StockCarPageDTO';
import type { StockCarsFilterWithDataDTO } from '../models/StockCarsFilterWithDataDTO';
import type { StockCarsTabFilter } from '../models/StockCarsTabFilter';
import type { StockFilterResponseDTO } from '../models/StockFilterResponseDTO';
import type { TransmTypeFilter } from '../models/TransmTypeFilter';
import type { VehicleTypes } from '../models/VehicleTypes';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StockService {

  /**
   * Get stock vehcles
   * @param branch
 *
 * 1 = Slough
 *
 * 2 = HighWycombe
 *
 * 3 = Maidenhead
 *
 * 4 = Watford
 *
 * 6 = HemelHempstead
 *
 * 7 = Romford
   * @param carsPerPage Кол-во машин на одной странице
   * @param pageNumber Номер страницы
   * @param tab Вкладка для отображения
 *
 * 0 = Used
 *
 * 1 = DeliveryMileage
 *
 * 2 = ExDemo
 *
 * 3 = New
 *
 * 4 = External
 *
 * 5 = MFLDirect
   * @param age Фильтр по возрасту машины. Значение соответствует выбранному N из выпадающего списка "up to N years old"
   * @param bodyType Выбранное значение из списка типов кузова. Значения не соответствуют 1 к 1 с
 * самими типами кузовов
 *
 * 0 = All
 *
 * 1 = Coupe
 *
 * 2 = Estate
 *
 * 3 = Hatchback
 *
 * 4 = MPV
 *
 * 5 = SUV
 *
 * 6 = Unleasted
   * @param vehicleType Тип транспортного средства. Передать null если нужно показать все.
 *
 * 0 = Undefined
 *
 * 1 = Bike (Motorcycle)
 *
 * 2 = Car
 *
 * 3 = Comp (Component vehicle)
 *
 * 4 = Hgv (Heavy Goods Vehicle)
 *
 * 5 = Van (Light Commercial Vehicle)
   * @param engineVolume Фильтр по литражу движка
 *
 * 0 = All
 *
 * 1 = Max13 (1L-1.3L)
 *
 * 2 = Min14Max16 (1.4L-1.6L)
 *
 * 3 = Min17Max19 (1.7L-1.9L)
 *
 * 4 = Min2Max25 (2L-2.5L)
 *
 * 5 = MoreThan25 (more than 2.5L)
   * @param fuelType Фильтр по типу топлива
   * @param excludeFuelType 
   * @param manufacturer Производитель, регистронезависимо.
 * Ставить null или пустую строку если поиск по производителю делать не нужно
   * @param model Название модели для поиска, регистронезависимо.
 * Ставить null или пустую строку если поиск по производителю делать не нужно
   * @param mileageMax Максимальный пробег (включительно)
   * @param mileageMin Минимальный пробег (включительно)
   * @param monthlyPaymentMax Максимальный ежемесячный платеж (включительно)
   * @param monthlyPaymentMin Минимальный ежемесячный платеж (включительно)
   * @param priceMax Максимальная стоимость авто (включительно)
   * @param priceMin Минимальная стоимость авто (включительно)
   * @param regNo 
   * @param isSpecialOffer 
   * @param sortByPriceAsc Порядок сортировки по цене (прямой или обратный)
   * @param isTransmissionAutomatic Тип коробки передач. null если все виды, true для автоматических коробок.
   * @param isMotability 
   * @param onlyUpdatedOrEmptyPrices 
   * @param models 
   * @param customModelIds 
   * @param trimIds 
   * @returns StockCarPageDTO Success
   * @throws ApiError
   */
  public static getStockVehicles(
branch?: BranchType,
carsPerPage?: number,
pageNumber?: number,
tab?: StockCarsTabFilter,
age?: number,
bodyType?: CarBodyTypeFilter,
vehicleType?: VehicleTypes,
engineVolume?: EngineSizeFilter,
fuelType?: Array<FuelTypeFilter>,
excludeFuelType?: Array<FuelTypeFilter>,
manufacturer?: string,
model?: string,
mileageMax?: number,
mileageMin?: number,
monthlyPaymentMax?: number,
monthlyPaymentMin?: number,
priceMax?: number,
priceMin?: number,
regNo?: string,
isSpecialOffer?: boolean,
sortByPriceAsc?: boolean,
isTransmissionAutomatic?: boolean,
isMotability?: boolean,
onlyUpdatedOrEmptyPrices?: boolean,
models?: Array<string>,
customModelIds?: Array<number>,
trimIds?: Array<number>,
): CancelablePromise<StockCarPageDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/Stock/vehicles',
      query: {
        'Branch': branch,
        'CarsPerPage': carsPerPage,
        'PageNumber': pageNumber,
        'Tab': tab,
        'Age': age,
        'BodyType': bodyType,
        'VehicleType': vehicleType,
        'EngineVolume': engineVolume,
        'FuelType': fuelType,
        'ExcludeFuelType': excludeFuelType,
        'Manufacturer': manufacturer,
        'Model': model,
        'MileageMax': mileageMax,
        'MileageMin': mileageMin,
        'MonthlyPaymentMax': monthlyPaymentMax,
        'MonthlyPaymentMin': monthlyPaymentMin,
        'PriceMax': priceMax,
        'PriceMin': priceMin,
        'RegNo': regNo,
        'IsSpecialOffer': isSpecialOffer,
        'SortByPriceAsc': sortByPriceAsc,
        'IsTransmissionAutomatic': isTransmissionAutomatic,
        'IsMotability': isMotability,
        'OnlyUpdatedOrEmptyPrices': onlyUpdatedOrEmptyPrices,
        'Models': models,
        'CustomModelIds': customModelIds,
        'TrimIds': trimIds,
      },
    });
  }

  /**
   * Get data for stock filters
   * @param tab
 *
 * 0 = Used
 *
 * 1 = DeliveryMileage
 *
 * 2 = ExDemo
 *
 * 3 = New
 *
 * 4 = External
 *
 * 5 = MFLDirect
   * @param page 
   * @param body
 *
 * 0 = All
 *
 * 1 = Coupe
 *
 * 2 = Estate
 *
 * 3 = Hatchback
 *
 * 4 = MPV
 *
 * 5 = SUV
 *
 * 6 = Unleasted
   * @param vehicle
 *
 * 0 = Undefined
 *
 * 1 = Bike (Motorcycle)
 *
 * 2 = Car
 *
 * 3 = Comp (Component vehicle)
 *
 * 4 = Hgv (Heavy Goods Vehicle)
 *
 * 5 = Van (Light Commercial Vehicle)
   * @param engine
 *
 * 0 = All
 *
 * 1 = Max13 (1L-1.3L)
 *
 * 2 = Min14Max16 (1.4L-1.6L)
 *
 * 3 = Min17Max19 (1.7L-1.9L)
 *
 * 4 = Min2Max25 (2L-2.5L)
 *
 * 5 = MoreThan25 (more than 2.5L)
   * @param fuel
 *
 * 0 = Any
 *
 * 1 = Diesel
 *
 * 2 = Petrol
 *
 * 3 = Electric
 *
 * 4 = Hybrid
   * @param age 
   * @param transm 
   * @param manuf 
   * @param model 
   * @param mileageMin 
   * @param mileageMax 
   * @param monthMin 
   * @param monthMax 
   * @param priceMin 
   * @param priceMax 
   * @param regno 
   * @param sort 
   * @returns StockCarsFilterWithDataDTO Success
   * @throws ApiError
   */
  public static getStockFilters(
tab?: StockCarsTabFilter,
page?: number,
body?: CarBodyTypeFilter,
vehicle?: VehicleTypes,
engine?: EngineSizeFilter,
fuel?: FuelTypeFilter,
age?: number,
transm?: boolean,
manuf?: string,
model?: string,
mileageMin?: number,
mileageMax?: number,
monthMin?: number,
monthMax?: number,
priceMin?: number,
priceMax?: number,
regno?: string,
sort?: boolean,
): CancelablePromise<StockCarsFilterWithDataDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/Stock/filters',
      query: {
        'tab': tab,
        'page': page,
        'body': body,
        'vehicle': vehicle,
        'engine': engine,
        'fuel': fuel,
        'age': age,
        'transm': transm,
        'manuf': manuf,
        'model': model,
        'mileageMin': mileageMin,
        'mileageMax': mileageMax,
        'monthMin': monthMin,
        'monthMax': monthMax,
        'priceMin': priceMin,
        'priceMax': priceMax,
        'regno': regno,
        'sort': sort,
      },
    });
  }

  /**
   * @param stockIds 
   * @returns MonthlyPaymentShortDto Success
   * @throws ApiError
   */
  public static getVehiclesStockPrecalculate(
stockIds?: Array<number>,
): CancelablePromise<Record<string, MonthlyPaymentShortDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/vehicles/stock/precalculate',
      query: {
        'stockIds': stockIds,
      },
    });
  }

  /**
   * Get vehicles in external stock
   * @param regNo 
   * @param carNum 
   * @param orderByHighestPrice 
   * @param filterTab
 *
 * 0 = Used
 *
 * 1 = DeliveryMileage
 *
 * 2 = ExDemo
 *
 * 3 = New
 *
 * 4 = External
   * @param filterVehicleType
 *
 * 0 = Undefined
 *
 * 1 = Bike (Motorcycle)
 *
 * 2 = Car
 *
 * 3 = Comp (Component vehicle)
 *
 * 4 = Hgv (Heavy Goods Vehicle)
 *
 * 5 = Van (Light Commercial Vehicle)
   * @param filterBranch 
   * @param filterMake 
   * @param filterModel 
   * @param filterCustomModel 
   * @param filterTrim 
   * @param filterDescription 
   * @param filterTransmission
 *
 * 0 = Any
 *
 * 1 = Manual
 *
 * 2 = Automatic
   * @param filterFuelType
 *
 * 0 = Any
 *
 * 1 = Diesel
 *
 * 2 = Petrol
 *
 * 3 = Electric
 *
 * 4 = Hybrid
   * @param filterExcludeFuelType
 *
 * 0 = Any
 *
 * 1 = Diesel
 *
 * 2 = Petrol
 *
 * 3 = Electric
 *
 * 4 = Hybrid
   * @param filterBodyStyle
 *
 * 0 = All
 *
 * 1 = Coupe
 *
 * 2 = Estate
 *
 * 3 = Hatchback
 *
 * 4 = MPV
 *
 * 5 = SUV
 *
 * 6 = Unleasted
   * @param filterAgeMin 
   * @param filterAgeMax 
   * @param filterEngineVolumeMin 
   * @param filterEngineVolumeMax 
   * @param filterPriceMin 
   * @param filterPriceMax 
   * @param filterStockType 
   * @param filterLocation 
   * @param filterProgress 
   * @param filterColour 
   * @param filterDoors 
   * @param filterEvent 
   * @param filterIsReserver 
   * @param filterIsOrdered 
   * @param filterIsSpecialOffer 
   * @param filterSpecialOfferTypes 
   * @param filterIsMotability 
   * @param filterFinanceFinanceType
 *
 * 1 = HP
 *
 * 2 = PCP
 *
 * 3 = CS0
 *
 * 5 = x4x4
 *
 * 6 = PCPZero
 *
 * 7 = FL
 *
 * 9 = BCH
   * @param filterFinanceMonthlyPaymentMin 
   * @param filterFinanceMonthlyPaymentMax 
   * @param filterFinanceDeposit 
   * @param filterFinanceTerm 
   * @param filterFinanceMiles 
   * @param filterStockReferenceDaysInStockMin 
   * @param filterStockReferenceDaysInStockMax 
   * @param filterStockReferenceReference 
   * @param filterRetailerAction 
   * @param filterFamily 
   * @param filterRetailerStatus 
   * @param filterRegisterableInMonth 
   * @param filterIsUndefined 
   * @param filterIsSupported 
   * @param filterWithoutEmptyPrice 
   * @param filterWithoutEmptyMontlyPayment 
   * @param filterWithoutEmptyPercentageSupport 
   * @param paggingPage 
   * @param paggingPageSize 
   * @param skipPagination 
   * @param onlyUpdatedOrEmptyPrices 
   * @returns ExternalStockFilteredResponseDTOPagedListData Success
   * @throws ApiError
   */
  public static getVehiclesExternalStock(
regNo?: string,
carNum?: string,
orderByHighestPrice?: boolean,
filterTab?: StockCarsTabFilter,
filterVehicleType?: VehicleTypes,
filterBranch?: number,
filterMake?: string,
filterModel?: Array<string>,
filterCustomModel?: Array<number>,
filterTrim?: Array<number>,
filterDescription?: Array<string>,
filterTransmission?: TransmTypeFilter,
filterFuelType?: FuelTypeFilter,
filterExcludeFuelType?: FuelTypeFilter,
filterBodyStyle?: CarBodyTypeFilter,
filterAgeMin?: number,
filterAgeMax?: number,
filterEngineVolumeMin?: number,
filterEngineVolumeMax?: number,
filterPriceMin?: number,
filterPriceMax?: number,
filterStockType?: Array<number>,
filterLocation?: Array<number>,
filterProgress?: Array<number>,
filterColour?: Array<string>,
filterDoors?: Array<number>,
filterEvent?: Array<number>,
filterIsReserver?: boolean,
filterIsOrdered?: boolean,
filterIsSpecialOffer?: boolean,
filterSpecialOfferTypes?: Array<number>,
filterIsMotability?: boolean,
filterFinanceFinanceType?: FinanceTypeEnum,
filterFinanceMonthlyPaymentMin?: number,
filterFinanceMonthlyPaymentMax?: number,
filterFinanceDeposit?: number,
filterFinanceTerm?: number,
filterFinanceMiles?: number,
filterStockReferenceDaysInStockMin?: number,
filterStockReferenceDaysInStockMax?: number,
filterStockReferenceReference?: string,
filterRetailerAction?: Array<string>,
filterFamily?: Array<string>,
filterRetailerStatus?: Array<string>,
filterRegisterableInMonth?: boolean,
filterIsUndefined?: boolean,
filterIsSupported?: boolean,
filterWithoutEmptyPrice?: boolean,
filterWithoutEmptyMontlyPayment?: boolean,
filterWithoutEmptyPercentageSupport?: boolean,
paggingPage?: number,
paggingPageSize?: number,
skipPagination?: boolean,
onlyUpdatedOrEmptyPrices?: boolean,
): CancelablePromise<ExternalStockFilteredResponseDTOPagedListData> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/vehicles/external-stock',
      query: {
        'RegNo': regNo,
        'CarNum': carNum,
        'OrderByHighestPrice': orderByHighestPrice,
        'Filter.Tab': filterTab,
        'Filter.VehicleType': filterVehicleType,
        'Filter.Branch': filterBranch,
        'Filter.Make': filterMake,
        'Filter.Model': filterModel,
        'Filter.CustomModel': filterCustomModel,
        'Filter.Trim': filterTrim,
        'Filter.Description': filterDescription,
        'Filter.Transmission': filterTransmission,
        'Filter.FuelType': filterFuelType,
        'Filter.ExcludeFuelType': filterExcludeFuelType,
        'Filter.BodyStyle': filterBodyStyle,
        'Filter.Age.Min': filterAgeMin,
        'Filter.Age.Max': filterAgeMax,
        'Filter.EngineVolume.Min': filterEngineVolumeMin,
        'Filter.EngineVolume.Max': filterEngineVolumeMax,
        'Filter.Price.Min': filterPriceMin,
        'Filter.Price.Max': filterPriceMax,
        'Filter.StockType': filterStockType,
        'Filter.Location': filterLocation,
        'Filter.Progress': filterProgress,
        'Filter.Colour': filterColour,
        'Filter.Doors': filterDoors,
        'Filter.Event': filterEvent,
        'Filter.IsReserver': filterIsReserver,
        'Filter.IsOrdered': filterIsOrdered,
        'Filter.IsSpecialOffer': filterIsSpecialOffer,
        'Filter.SpecialOfferTypes': filterSpecialOfferTypes,
        'Filter.IsMotability': filterIsMotability,
        'Filter.Finance.FinanceType': filterFinanceFinanceType,
        'Filter.Finance.MonthlyPayment.Min': filterFinanceMonthlyPaymentMin,
        'Filter.Finance.MonthlyPayment.Max': filterFinanceMonthlyPaymentMax,
        'Filter.Finance.Deposit': filterFinanceDeposit,
        'Filter.Finance.Term': filterFinanceTerm,
        'Filter.Finance.Miles': filterFinanceMiles,
        'Filter.StockReference.DaysInStock.Min': filterStockReferenceDaysInStockMin,
        'Filter.StockReference.DaysInStock.Max': filterStockReferenceDaysInStockMax,
        'Filter.StockReference.Reference': filterStockReferenceReference,
        'Filter.RetailerAction': filterRetailerAction,
        'Filter.Family': filterFamily,
        'Filter.RetailerStatus': filterRetailerStatus,
        'Filter.RegisterableInMonth': filterRegisterableInMonth,
        'Filter.IsUndefined': filterIsUndefined,
        'Filter.IsSupported': filterIsSupported,
        'Filter.WithoutEmptyPrice': filterWithoutEmptyPrice,
        'Filter.WithoutEmptyMontlyPayment': filterWithoutEmptyMontlyPayment,
        'Filter.WithoutEmptyPercentageSupport': filterWithoutEmptyPercentageSupport,
        'Pagging.Page': paggingPage,
        'Pagging.PageSize': paggingPageSize,
        'SkipPagination': skipPagination,
        'OnlyUpdatedOrEmptyPrices': onlyUpdatedOrEmptyPrices,
      },
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * Run price updates for vehicles in external stock
   * @returns boolean Success
   * @throws ApiError
   */
  public static putVehiclesExternalStock(): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/vehicles/external-stock',
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * Get concrete vehicle in external stock
   * @param id 
   * @returns ExternalStockFilteredResponseDTO Success
   * @throws ApiError
   */
  public static getVehiclesExternalStock1(
id: number,
): CancelablePromise<ExternalStockFilteredResponseDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/vehicles/external-stock/{id}',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * Get available filters for external stock vehicles
   * @param tab
 *
 * 0 = Used
 *
 * 1 = DeliveryMileage
 *
 * 2 = ExDemo
 *
 * 3 = New
 *
 * 4 = External
   * @param vehicleType
 *
 * 0 = Undefined
 *
 * 1 = Bike (Motorcycle)
 *
 * 2 = Car
 *
 * 3 = Comp (Component vehicle)
 *
 * 4 = Hgv (Heavy Goods Vehicle)
 *
 * 5 = Van (Light Commercial Vehicle)
   * @param branch 
   * @param make 
   * @param model 
   * @param customModel 
   * @param trim 
   * @param description 
   * @param transmission
 *
 * 0 = Any
 *
 * 1 = Manual
 *
 * 2 = Automatic
   * @param fuelType
 *
 * 0 = Any
 *
 * 1 = Diesel
 *
 * 2 = Petrol
 *
 * 3 = Electric
 *
 * 4 = Hybrid
   * @param bodyStyle
 *
 * 0 = All
 *
 * 1 = Coupe
 *
 * 2 = Estate
 *
 * 3 = Hatchback
 *
 * 4 = MPV
 *
 * 5 = SUV
 *
 * 6 = Unleasted
   * @param ageMin 
   * @param ageMax 
   * @param engineVolumeMin 
   * @param engineVolumeMax 
   * @param priceMin 
   * @param priceMax 
   * @param stockType 
   * @param location 
   * @param progress 
   * @param colour 
   * @param doors 
   * @param event 
   * @param isReserver 
   * @param isOrdered 
   * @param isSpecialOffer 
   * @param specialOfferTypes 
   * @param isMotability 
   * @param financeFinanceType
 *
 * 1 = HP
 *
 * 2 = PCP
 *
 * 3 = CS0
 *
 * 5 = x4x4
 *
 * 6 = PCPZero
 *
 * 7 = FL
 *
 * 9 = BCH
   * @param financeMonthlyPaymentMin 
   * @param financeMonthlyPaymentMax 
   * @param financeDeposit 
   * @param financeTerm 
   * @param financeMiles 
   * @param stockReferenceDaysInStockMin 
   * @param stockReferenceDaysInStockMax 
   * @param stockReferenceReference 
   * @param retailerAction 
   * @param family 
   * @param retailerStatus 
   * @param registerableInMonth 
   * @param isUndefined 
   * @param isSupported 
   * @param withoutEmptyPrice 
   * @param withoutEmptyMontlyPayment 
   * @returns StockFilterResponseDTO Success
   * @throws ApiError
   */
  public static getVehiclesStockExternalFilters(
tab?: StockCarsTabFilter,
vehicleType?: VehicleTypes,
branch?: number,
make?: string,
model?: Array<string>,
customModel?: Array<number>,
trim?: Array<number>,
description?: Array<string>,
transmission?: TransmTypeFilter,
fuelType?: FuelTypeFilter,
bodyStyle?: CarBodyTypeFilter,
ageMin?: number,
ageMax?: number,
engineVolumeMin?: number,
engineVolumeMax?: number,
priceMin?: number,
priceMax?: number,
stockType?: Array<number>,
location?: Array<number>,
progress?: Array<number>,
colour?: Array<string>,
doors?: Array<number>,
event?: Array<number>,
isReserver?: boolean,
isOrdered?: boolean,
isSpecialOffer?: boolean,
specialOfferTypes?: Array<number>,
isMotability?: boolean,
financeFinanceType?: FinanceTypeEnum,
financeMonthlyPaymentMin?: number,
financeMonthlyPaymentMax?: number,
financeDeposit?: number,
financeTerm?: number,
financeMiles?: number,
stockReferenceDaysInStockMin?: number,
stockReferenceDaysInStockMax?: number,
stockReferenceReference?: string,
retailerAction?: Array<string>,
family?: Array<string>,
retailerStatus?: Array<string>,
registerableInMonth?: boolean,
isUndefined?: boolean,
isSupported?: boolean,
withoutEmptyPrice?: boolean,
withoutEmptyMontlyPayment?: boolean,
): CancelablePromise<StockFilterResponseDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/vehicles/stock/external-filters',
      query: {
        'Tab': tab,
        'VehicleType': vehicleType,
        'Branch': branch,
        'Make': make,
        'Model': model,
        'CustomModel': customModel,
        'Trim': trim,
        'Description': description,
        'Transmission': transmission,
        'FuelType': fuelType,
        'BodyStyle': bodyStyle,
        'Age.Min': ageMin,
        'Age.Max': ageMax,
        'EngineVolume.Min': engineVolumeMin,
        'EngineVolume.Max': engineVolumeMax,
        'Price.Min': priceMin,
        'Price.Max': priceMax,
        'StockType': stockType,
        'Location': location,
        'Progress': progress,
        'Colour': colour,
        'Doors': doors,
        'Event': event,
        'IsReserver': isReserver,
        'IsOrdered': isOrdered,
        'IsSpecialOffer': isSpecialOffer,
        'SpecialOfferTypes': specialOfferTypes,
        'IsMotability': isMotability,
        'Finance.FinanceType': financeFinanceType,
        'Finance.MonthlyPayment.Min': financeMonthlyPaymentMin,
        'Finance.MonthlyPayment.Max': financeMonthlyPaymentMax,
        'Finance.Deposit': financeDeposit,
        'Finance.Term': financeTerm,
        'Finance.Miles': financeMiles,
        'StockReference.DaysInStock.Min': stockReferenceDaysInStockMin,
        'StockReference.DaysInStock.Max': stockReferenceDaysInStockMax,
        'StockReference.Reference': stockReferenceReference,
        'RetailerAction': retailerAction,
        'Family': family,
        'RetailerStatus': retailerStatus,
        'RegisterableInMonth': registerableInMonth,
        'IsUndefined': isUndefined,
        'IsSupported': isSupported,
        'WithoutEmptyPrice': withoutEmptyPrice,
        'WithoutEmptyMontlyPayment': withoutEmptyMontlyPayment,
      },
      errors: {
        400: `Bad Request`,
      },
    });
  }

  /**
   * Get available filters for stock vehicles
   * @param tab
 *
 * 0 = Used
 *
 * 1 = DeliveryMileage
 *
 * 2 = ExDemo
 *
 * 3 = New
 *
 * 4 = External
   * @param vehicleType
 *
 * 0 = Undefined
 *
 * 1 = Bike (Motorcycle)
 *
 * 2 = Car
 *
 * 3 = Comp (Component vehicle)
 *
 * 4 = Hgv (Heavy Goods Vehicle)
 *
 * 5 = Van (Light Commercial Vehicle)
   * @param branch 
   * @param make 
   * @param model 
   * @param customModel 
   * @param description 
   * @param transmission
 *
 * 0 = Any
 *
 * 1 = Manual
 *
 * 2 = Automatic
   * @param fuelType
 *
 * 0 = Any
 *
 * 1 = Diesel
 *
 * 2 = Petrol
 *
 * 3 = Electric
 *
 * 4 = Hybrid
   * @param bodyStyle
 *
 * 0 = All
 *
 * 1 = Coupe
 *
 * 2 = Estate
 *
 * 3 = Hatchback
 *
 * 4 = MPV
 *
 * 5 = SUV
 *
 * 6 = Unleasted
   * @param ageMin 
   * @param ageMax 
   * @param engineVolumeMin 
   * @param engineVolumeMax 
   * @param priceMin 
   * @param priceMax 
   * @param stockType 
   * @param location 
   * @param progress 
   * @param colour 
   * @param doors 
   * @param event 
   * @param isReserver 
   * @param isOrdered 
   * @param isSpecialOffer 
   * @param specialOfferTypes 
   * @param isMotability 
   * @param financeFinanceType
 *
 * 1 = HP
 *
 * 2 = PCP
 *
 * 3 = CS0
 *
 * 5 = x4x4
 *
 * 6 = PCPZero
 *
 * 7 = FL
 *
 * 9 = BCH
   * @param financeMonthlyPaymentMin 
   * @param financeMonthlyPaymentMax 
   * @param financeDeposit 
   * @param financeTerm 
   * @param financeMiles 
   * @param stockReferenceDaysInStockMin 
   * @param stockReferenceDaysInStockMax 
   * @param stockReferenceReference 
   * @param retailerAction 
   * @param family 
   * @param retailerStatus 
   * @param registerableInMonth 
   * @param isUndefined 
   * @param isSupported 
   * @param withoutEmptyPrice 
   * @param withoutEmptyMontlyPayment 
   * @returns boolean Success
   * @throws ApiError
   */
  public static getVehiclesStockFilters(
tab?: StockCarsTabFilter,
vehicleType?: VehicleTypes,
branch?: number,
make?: string,
model?: Array<string>,
customModel?: Array<number>,
description?: Array<string>,
transmission?: TransmTypeFilter,
fuelType?: FuelTypeFilter,
bodyStyle?: CarBodyTypeFilter,
ageMin?: number,
ageMax?: number,
engineVolumeMin?: number,
engineVolumeMax?: number,
priceMin?: number,
priceMax?: number,
stockType?: Array<number>,
location?: Array<number>,
progress?: Array<number>,
colour?: Array<string>,
doors?: Array<number>,
event?: Array<number>,
isReserver?: boolean,
isOrdered?: boolean,
isSpecialOffer?: boolean,
specialOfferTypes?: Array<number>,
isMotability?: boolean,
financeFinanceType?: FinanceTypeEnum,
financeMonthlyPaymentMin?: number,
financeMonthlyPaymentMax?: number,
financeDeposit?: number,
financeTerm?: number,
financeMiles?: number,
stockReferenceDaysInStockMin?: number,
stockReferenceDaysInStockMax?: number,
stockReferenceReference?: string,
retailerAction?: Array<string>,
family?: Array<string>,
retailerStatus?: Array<string>,
registerableInMonth?: boolean,
isUndefined?: boolean,
isSupported?: boolean,
withoutEmptyPrice?: boolean,
withoutEmptyMontlyPayment?: boolean,
): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/vehicles/stock/filters',
      query: {
        'Tab': tab,
        'VehicleType': vehicleType,
        'Branch': branch,
        'Make': make,
        'Model': model,
        'CustomModel': customModel,
        'Description': description,
        'Transmission': transmission,
        'FuelType': fuelType,
        'BodyStyle': bodyStyle,
        'Age.Min': ageMin,
        'Age.Max': ageMax,
        'EngineVolume.Min': engineVolumeMin,
        'EngineVolume.Max': engineVolumeMax,
        'Price.Min': priceMin,
        'Price.Max': priceMax,
        'StockType': stockType,
        'Location': location,
        'Progress': progress,
        'Colour': colour,
        'Doors': doors,
        'Event': event,
        'IsReserver': isReserver,
        'IsOrdered': isOrdered,
        'IsSpecialOffer': isSpecialOffer,
        'SpecialOfferTypes': specialOfferTypes,
        'IsMotability': isMotability,
        'Finance.FinanceType': financeFinanceType,
        'Finance.MonthlyPayment.Min': financeMonthlyPaymentMin,
        'Finance.MonthlyPayment.Max': financeMonthlyPaymentMax,
        'Finance.Deposit': financeDeposit,
        'Finance.Term': financeTerm,
        'Finance.Miles': financeMiles,
        'StockReference.DaysInStock.Min': stockReferenceDaysInStockMin,
        'StockReference.DaysInStock.Max': stockReferenceDaysInStockMax,
        'StockReference.Reference': stockReferenceReference,
        'RetailerAction': retailerAction,
        'Family': family,
        'RetailerStatus': retailerStatus,
        'RegisterableInMonth': registerableInMonth,
        'IsUndefined': isUndefined,
        'IsSupported': isSupported,
        'WithoutEmptyPrice': withoutEmptyPrice,
        'WithoutEmptyMontlyPayment': withoutEmptyMontlyPayment,
      },
      errors: {
        400: `Bad Request`,
      },
    });
  }

}
