import uuid from 'react-uuid'
import { ChatToken } from '../models/contactUs/ChatToken'

const LOCAL_STORAGE_ITEM_ID =
  process.env.REACT_APP_MODE === 'production' ? 'CONNECT_WEBCHAT_WIDGET' : 'CONNECT_WEBCHAT_WIDGET_DEV'

function getStoredChatSessionData(key: string) {
  const item = localStorage.getItem(key)
  let storedData: ChatToken

  if (!item) {
    return null
  }

  try {
    storedData = JSON.parse(item)
  } catch (e) {
    return null
  }

  return storedData
}

function storeSessionData(key: string, data: ChatToken) {
  localStorage.setItem(key, JSON.stringify(data))
}

const fetchNewChatSession = () => {
  const newTokenData = {
    identity: 'Visitor',
    userId: uuid(),
  }
  storeSessionData(LOCAL_STORAGE_ITEM_ID, newTokenData)
  return newTokenData
}

export const getChatSessionData = (): ChatToken => {
  let sessionData = getStoredChatSessionData(LOCAL_STORAGE_ITEM_ID)
  if (!sessionData) sessionData = fetchNewChatSession()
  return sessionData
}
