import { ToastOptions, toast } from "react-toastify";


export const errorOptions: ToastOptions = { position: 'bottom-left', autoClose: 5000 };
export const successOptions: ToastOptions = { autoClose: 2000, position: 'bottom-left' };

export const conversationOptions: ToastOptions = { autoClose: false, position: 'bottom-left', theme: "colored", closeOnClick: false };

export const successToast = (message: string): void => {
    toast.success(message, successOptions);
};

export const errorToast = (e?: Error, message?: string): void => {    
    toast.error((message + " " + getErrorMessage(e)).trim(), errorOptions);
};

export const getErrorMessage = (error?: Error) => {
    return error ? error.message : "";
};

export const loginErrorToast = (e: Error): void => {
    errorToast(e, "Authentication failed.");
};

export const saveSuccessToast = (): void => {
    successToast("The changes have been saved.");
};

export const saveErrorToast = (e: Error): void => {
    errorToast(e, "Operation couldn't be completed");
};