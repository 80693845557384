import React, { FC, Suspense, useEffect, useState } from 'react'
import LoaderSpinner from '../common/loader/LoaderSpinner'
import ContactUsRight from '../contactUs/rightSide/ContactUsRight'
//import Banner from '../banner/Banner'
//import { WebchatWidget } from '../twilioChat/WebchatWidget'
import HelmetByUrl from '../common/HelmetByUrl'
import useImportScript from '../../utils/useImportScript'
import Menu from '../../layouts/menu/Menu'
import ContactsCaption from './contactsCaption/ContactsCaption'
import ChatWrapper from '../chat/ChatWrapper'
import VirtualAssistant from '../virtualAssistant/VirtualAssistant'
import { useAppDispatch, useAppSelector } from '../common/hooks/reduxHooks'
import notificationMessage from '../../assets/sounds/notification_message.mp3'
import { contactUsActions } from '../../utils/slices/contactUsChatSlice'
import { TwilioMessage } from '../../models/contactUs/TwilioMessage'

import { setConnectionClosed } from '../../utils/slices/envSlice'
import EventCaption from './eventCaption/Index'
//import Footer from './footer/Footer'
import styles from './styles.module.scss'

const Footer = React.lazy(() => import('./footer/Footer'))

const Layout: FC<React.PropsWithChildren<{}>> = ({ children }) => {
  useImportScript('/js/partials/header.js')
  const dispatch = useAppDispatch()
  const { hubConnection } = useAppSelector((state) => state.environment)
  const { conversationId, vaConversationId } = useAppSelector((state) => state.contactUs)
  const audioMessage = new Audio(notificationMessage)
  const [showContactUs, setShowContactUs] = useState<boolean>(false)

  const handleOpenContactUs = () => {
    setShowContactUs(true)
  }

  const handleCloseContactUs = () => {
    setShowContactUs(false)
  }

  const addMessage = (newMessage: TwilioMessage) => {
    dispatch(contactUsActions.addMessage(newMessage))
  }

  const addVaMessage = (newMessage: TwilioMessage) => {
    dispatch(contactUsActions.addVaMessage(newMessage))
  }

  useEffect(() => {
    if (hubConnection && (conversationId || vaConversationId)) {
      hubConnection?.off('ConservationNewMessageNotification')
      hubConnection?.off('CloseConversationMessageNotification')
      hubConnection.on('ConservationNewMessageNotification', (inMessage: any) => {
        if (inMessage.conversationSid === conversationId && inMessage.message.type !== 1) {
          addMessage(inMessage.message)
          try {
            audioMessage.play()
          } catch (error) {}
        } else if (inMessage.conversationSid === vaConversationId && inMessage.message.type !== 1) {
          addVaMessage(inMessage.message)
          try {
            audioMessage.play()
          } catch (error) {}
        }
      })
      hubConnection.on('CloseConversationMessageNotification', (message) => {
        if (message.conversationSid === conversationId) {
          dispatch(contactUsActions.setConversationClosed(true))
        } else if (message.conversationSid === vaConversationId)
          dispatch(contactUsActions.setVaConversationClosed(true))

        //dispatch(setConnectionClosed(true))
      })
    }
    return () => {
      hubConnection?.off('ConservationNewMessageNotification')
      hubConnection?.off('CloseConversationMessageNotification')
    }
  }, [hubConnection, conversationId, vaConversationId])

  useEffect(() => {
    if (hubConnection) {
      dispatch(setConnectionClosed(false))
      hubConnection.onclose(() => {
        dispatch(setConnectionClosed(true))
      })
    }
  }, [hubConnection])

  return (
    <>
      <HelmetByUrl />

      <div className={`wrapper--100vh wrapper--top ${styles.wrapperTop}`} id='wrapper'>
        <div className='opened-menu-slide home-page'>
          <LoaderSpinner />

          {showContactUs && <ContactUsRight closeContactUs={handleCloseContactUs} />}

          <Menu onContactUsClick={handleOpenContactUs} />

          {/* <Banner /> */}

          <EventCaption />

          <ContactsCaption />

          {children}

          <Suspense fallback={<></>}>
            <Footer />
            <VirtualAssistant />
            <ChatWrapper />
          </Suspense>
        </div>
      </div>
    </>
  )
}

export default Layout
