import { useEffect } from 'react';



export const useImportScript = (resourceUrl: string, dependencyList: any = [], props: any = null) => {
  useEffect(() => {



    const script: any = document.createElement('script');
    script.src = resourceUrl;
    script.async = resourceUrl;
    script.crossorigin = true;
    if (props?.integrity)
      script.integrity = props?.integrity //"sha512-yBmOHVWuWT6HOjfgPYkFe70bboby/BTj9TGHXTlEatWnYkW5fFezXqW9ZgNtuRUqHWrzNXVsqu6cKm3Y04kHMA=="


    const id = resourceUrl.replaceAll("/", "_");
    script.id = id;

    // удаляем если был загружен ранее
    if (document.getElementById(id))
      document?.getElementById(id)?.remove();

    document.body.appendChild(script);


    return () => {


    }



  }, [resourceUrl, ...dependencyList]);
};
export const useImportCss = (resourceUrl: string, dependencyList: any = []) => {
  useEffect(() => {



    const script = document.createElement('link');
    script.href = resourceUrl;
    script.rel = "stylesheet"

    const id = resourceUrl.replaceAll("/", "_");
    script.id = id;

    // удаляем если был загружен ранее
    if (document.getElementById(id))
      document.getElementById(id)?.remove();

    document.body.appendChild(script);


    return () => {


    }



  }, [resourceUrl, ...dependencyList]);
};

export default useImportScript;