import { UseQueryOptions, useQuery } from 'react-query'
import { ApiError, EventService } from '../../../../api/generated'

export const useFetchEventGetEventsQueryKey = 'GetFetchEventGetEventsQuery'

const fetchEventGetEvents = async () => {
  return await EventService.getEventGetEvents()
}

export const useFetchEventGetEventsQuery = <TQueryKey extends Array<unknown> = unknown[]>(
  queryKey?: TQueryKey,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof fetchEventGetEvents>>,
      ApiError,
      Awaited<ReturnType<typeof fetchEventGetEvents>>,
      unknown[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => useQuery([useFetchEventGetEventsQueryKey, ...(queryKey ?? [{}])], () => fetchEventGetEvents(), options)
