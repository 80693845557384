/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = Any
 *
 * 1 = Manual
 *
 * 2 = Automatic
 */
export enum TransmTypeFilter {
  Any = 0,
  Manual = 1,
  Automatic = 2,
}
