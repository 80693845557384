import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
//import * as utils from "../../utils"

const App = ({ title, description, img = null }) => {
  let urlCanonical = window.location.origin + window.location.pathname;
  let url = window.location.href;

  useEffect(() => {

    if (title) {
      if (process.env.REACT_APP_MODE == 'production') {
        window.gtag('event', 'virtual_pageview', {
          'page_url': url,
          'page_title': title
        });
      }

      // utils.dataLayerPush({
      //   'event': 'virtualPageview',
      //   'pageUrl': url,
      //   'pageTitle': title
      // });
    }

  }, [url, title]);

  return <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="canonical" href={urlCanonical} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:url" content={urlCanonical} />
    <meta property="og:type" content="website" />
    <meta name='robots' content='index, follow' />
    {img && <meta property="og:image" content={img} />}

  </Helmet>

}

export default App;