import { fetchApi } from './fetch';

const URL_SCHEDULES = "Branch/Schedules";
const URL_LOOKUP = "Branch/Distance";


const fetchGetBranches = () => fetchApi(URL_SCHEDULES, "GET");
const fetchGetDistance = (params) => fetchApi(URL_LOOKUP + params, "GET");

export const branchesApi = {
    fetchGetBranches,
    fetchGetDistance  
};