import { FC, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import img1 from '../../assets/images/error/Error_image.png'
import './Error.scss'
import { useLocation } from 'react-router-dom'
import { useErrorBoundary } from 'react-error-boundary'

const Error: FC = () => {
  const { pathname } = useLocation()
  const originalPathname = useRef(pathname)

  const { resetBoundary } = useErrorBoundary()

  useEffect(() => {
    if (pathname !== originalPathname.current) {
      resetBoundary()
    }
  }, [pathname])

  return (
    <>
      <Helmet>
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>
      <div className='page--100vh-min'>
        <div className='error__page'>
          <div className='error__page__inner'>
            <div className='error__page__image'>
              <picture>
                <source media='(min-width: 768px)' srcSet={img1} />
                <img src={img1} alt='Error' />
              </picture>
            </div>
          </div>
          <div className='error__page__text'>
            <h1 className='title'>Sorry, an error occurred</h1>
            <h3>we are already working on it</h3>
          </div>
        </div>
        <div className='error__page__form'>
          <button
            className='btn btn-red'
            type='button'
            onClick={() => {
              window.location.href = '/'
            }}
          >
            Return
          </button>
        </div>
      </div>
    </>
  )
}

export default Error
