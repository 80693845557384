/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 0 = NotChosen
 *
 * 1 = Car
 *
 * 2 = Lcv
 */
export enum VarClassEnum {
  NotChosen = 0,
  Car = 1,
  Lcv = 2,
}
