import React, { useState } from 'react'
import PureModal from 'react-pure-modal'
import 'react-pure-modal/dist/react-pure-modal.min.css'
import { Branch } from '../../../models/branch/Branch'
import { Collapse } from 'react-collapse'
import { Departaments } from '../../../models/branch/Enum'
import filter from 'lodash/filter'
import moment from 'moment'
import './modal.scss'

const Modal = ({ showModal, onClose, branch }: { showModal: any; onClose: any; branch: Branch }) => {
  const onCloseModal = () => {
    onClose()
  }

  const [opened, setOpened] = useState<number>(branch.id == 3 ? 3 : 1)

  const handleOnClick = (dep: number) => {
    setOpened(dep)
  }

  return (
    <>
      <PureModal className='pure-modal__contact' width='100%' isOpen={showModal} onClose={onCloseModal}>
        <div
          id='modal-map-contacts'
          className='modal-map-contacts fancybox-content'
          style={{ display: 'inline-block', overflow: 'hidden' }}
        >
          <div className='title'>{branch.branchName}</div>
          <div className='modal-map__content' style={{ display: 'flex', width: 'auto' }}>
            <div className='map'>
              <iframe src={branch.src} frameBorder='0'></iframe>
            </div>
            <div className='content__contacts'>
              <div className='title'>Contact Details</div>
              <a href={`tel:${branch.salesPhone}`}>
                <div className='content__contacts-phone'>
                  <img src='/img/r_phone.png' alt='Slough branch Phone' title='Slough branch Phone' />
                  {branch.salesPhone}
                </div>
              </a>
              <div className='content__contacts-adress' style={{ wordBreak: 'break-word' }}>
                <img src='/img/r_marker.png' alt='Slough address marker' title='Slough address marker' />
                {branch.address}
              </div>
              <div className='accordion ui-accordion ui-widget ui-helper-reset'>
                {branch.id != 3 && (
                  <>
                    <div
                      onClick={() => {
                        handleOnClick(Departaments.Salse)
                      }}
                      className='ui-accordion-header ui-corner-top ui-state-default ui-accordion-header-active ui-state-active ui-accordion-icons'
                      style={{ background: '#b72126', color: '#fff' }}
                      role='tab'
                      id='ui-id-1'
                      aria-controls='ui-id-2'
                      aria-selected='true'
                      aria-expanded='true'
                    >
                      <span className='ui-accordion-header-icon ui-icon ui-icon-triangle-1-s'></span>Sales &nbsp;
                      <span className='oh'>Opening hours</span>
                    </div>

                    <Collapse isOpened={opened == Departaments.Salse}>
                      <div
                        className='ui-accordion-content ui-corner-bottom ui-helper-reset ui-widget-content ui-accordion-content-active'
                        id='ui-id-2'
                        aria-labelledby='ui-id-1'
                        role='tabpanel'
                        aria-hidden='false'
                      >
                        <div className='oh-table'>
                          {filter(branch.depSchedule, { depId: Departaments.Salse }).map((e: any, i: number) => (
                            <>
                              <div key={i} className='row'>
                                <div className='col'>{moment.weekdays(e.dayOfWeek)}</div>
                                <div className='col'>
                                  {e.isOpen ? `${e.openingHours} - ${e.closureHours}` : 'Closed'}
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </Collapse>
                  </>
                )}

                <div
                  onClick={() => {
                    handleOnClick(Departaments.Service)
                  }}
                  className='ui-accordion-header ui-corner-top ui-state-default ui-accordion-header-active ui-state-active ui-accordion-icons'
                  style={{ background: '#b72126', color: '#fff' }}
                  role='tab'
                  id='ui-id-1'
                  aria-controls='ui-id-2'
                  aria-selected='true'
                  aria-expanded='true'
                >
                  <span className='ui-accordion-header-icon ui-icon ui-icon-triangle-1-s'></span>Service &nbsp;
                  <span className='oh'>Opening hours</span>
                </div>

                <Collapse isOpened={opened == Departaments.Service}>
                  <div
                    className='ui-accordion-content ui-corner-bottom ui-helper-reset ui-widget-content ui-accordion-content-active'
                    id='ui-id-2'
                    aria-labelledby='ui-id-1'
                    role='tabpanel'
                    aria-hidden='false'
                  >
                    <div className='oh-table'>
                      {filter(branch.depSchedule, { depId: Departaments.Service }).map((e: any, i: number) => (
                        <>
                          <div key={i} className='row'>
                            <div className='col'>{moment.weekdays(e.dayOfWeek)}</div>
                            <div className='col'>{e.isOpen ? `${e.openingHours} - ${e.closureHours}` : 'Closed'}</div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </Collapse>

                <div
                  onClick={() => {
                    handleOnClick(Departaments.Parts)
                  }}
                  className='ui-accordion-header ui-corner-top ui-state-default ui-accordion-header-active ui-state-active ui-accordion-icons'
                  style={{ background: '#b72126', color: '#fff' }}
                  role='tab'
                  id='ui-id-1'
                  aria-controls='ui-id-2'
                  aria-selected='true'
                  aria-expanded='true'
                >
                  <span className='ui-accordion-header-icon ui-icon ui-icon-triangle-1-s'></span>Parts &nbsp;
                  <span className='oh'>Opening hours</span>
                </div>

                <Collapse isOpened={opened == Departaments.Parts}>
                  <div
                    className='ui-accordion-content ui-corner-bottom ui-helper-reset ui-widget-content ui-accordion-content-active'
                    id='ui-id-2'
                    aria-labelledby='ui-id-1'
                    role='tabpanel'
                    aria-hidden='false'
                  >
                    <div className='oh-table'>
                      {filter(branch.depSchedule, { depId: Departaments.Parts }).map((e: any, i: number) => (
                        <>
                          <div key={i} className='row'>
                            <div className='col'>{moment.weekdays(e.dayOfWeek)}</div>
                            <div className='col'>{e.isOpen ? `${e.openingHours} - ${e.closureHours}` : 'Closed'}</div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </PureModal>
    </>
  )
}

export default Modal
