import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ContentLogSate } from '../../models/contentLog/ContentLogSate'
import { ContentLogDto } from '../../models/contentLog/ContentLogDto'

const initialModel: ContentLogSate = {
  isLoading: false,
  newCarOffersContent: undefined,
  newVanOffersContent: undefined,
  valueCar: undefined,
  motabilityCarScheme: undefined,
  newCarsOffersCorsaContent: undefined,
  newCarsOffersAstraContent: undefined,
  newCarsOffersAstraSportContent: undefined,
  newCarsOffersComboElectricContent: undefined,
  newCarsOffersComboCargoContent: undefined,
  newCarsOffersMokkaElectricContent: undefined,
  newCarsOffersMokkaContent: undefined,
  newCarsOffersGrandlandContent: undefined,
  newCarsOffersCrosslandContent: undefined,
  newVansOffersElectricComboCargoContent: undefined,
  newVansOffersVivaroContent: undefined,
  newVansOffersVivaroElectricContent: undefined,
  newVansOffersMovanoContent: undefined,
  newVansOffersMovanoElectricContent: undefined,
  servicePlanContent: undefined,
  newCarsOffersVivaroElectricContent: undefined,
  partRequestContent: undefined,
}

const contentLogSlice = createSlice({
  name: 'contentLogFaqSlice',
  initialState: initialModel,
  reducers: {
    setNewCarOffersContent(state, action: PayloadAction<ContentLogDto>) {
      state.newCarOffersContent = action.payload
    },
    setNewVanOffersContent(state, action: PayloadAction<ContentLogDto>) {
      state.newVanOffersContent = action.payload
    },
    setValueCarContent(state, action: PayloadAction<ContentLogDto>) {
      state.valueCar = action.payload
    },
    setMotabilityCarSchemeContent(state, action: PayloadAction<ContentLogDto>) {
      state.motabilityCarScheme = action.payload
    },
    setNewCarsOffersCorsaContent(state, action: PayloadAction<ContentLogDto>) {
      state.newCarsOffersCorsaContent = action.payload
      state.isLoading = false
    },
    setNewCarsOffersAstraContent(state, action: PayloadAction<ContentLogDto>) {
      state.newCarsOffersAstraContent = action.payload
      state.isLoading = false
    },
    setNewCarsOffersAstraSportContent(state, action: PayloadAction<ContentLogDto>) {
      state.newCarsOffersAstraSportContent = action.payload
      state.isLoading = false
    },
    setNewCarsOffersVivaroElectricContent(state, action: PayloadAction<ContentLogDto>) {
      state.newCarsOffersVivaroElectricContent = action.payload
      state.isLoading = false
    },
    setNewCarsOffersComboElectricContent(state, action: PayloadAction<ContentLogDto>) {
      state.newCarsOffersComboElectricContent = action.payload
      state.isLoading = false
    },
    setNewCarsOffersMokkaElectricContent(state, action: PayloadAction<ContentLogDto>) {
      state.newCarsOffersMokkaElectricContent = action.payload
      state.isLoading = false
    },
    setNewCarsOffersMokkaContent(state, action: PayloadAction<ContentLogDto>) {
      state.newCarsOffersMokkaContent = action.payload
      state.isLoading = false
    },
    setNewCarsOffersGrandlandContent(state, action: PayloadAction<ContentLogDto>) {
      state.newCarsOffersGrandlandContent = action.payload
      state.isLoading = false
    },
    setNewCarsOffersCrosslandContent(state, action: PayloadAction<ContentLogDto>) {
      state.newCarsOffersCrosslandContent = action.payload
      state.isLoading = false
    },
    setNewCarsOffersComboCargoContent(state, action: PayloadAction<ContentLogDto>) {
      state.newCarsOffersComboCargoContent = action.payload
    },
    setNewVansOffersElectricComboCargoContent(state, action: PayloadAction<ContentLogDto>) {
      state.newVansOffersElectricComboCargoContent = action.payload
    },
    setNewVansOffersVivaroContent(state, action: PayloadAction<ContentLogDto>) {
      state.newVansOffersVivaroContent = action.payload
    },
    setNewVansOffersVivaroElectricContent(state, action: PayloadAction<ContentLogDto>) {
      state.newVansOffersVivaroElectricContent = action.payload
      state.isLoading = false
    },
    setNewVansOffersMovanoContent(state, action: PayloadAction<ContentLogDto>) {
      state.newVansOffersMovanoContent = action.payload
    },
    setNewVansOffersMovanoElectricContent(state, action: PayloadAction<ContentLogDto>) {
      state.newVansOffersMovanoElectricContent = action.payload
    },

    setServicePlanContent(state, action: PayloadAction<ContentLogDto>) {
      state.servicePlanContent = action.payload
    },
    setPartRequestContent(state, action: PayloadAction<ContentLogDto>) {
      state.partRequestContent = action.payload
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
  },
})

export default contentLogSlice
export const { reducer: contentLogSliceReducer, actions: contentLogSliceActions } = contentLogSlice
